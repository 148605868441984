<template>
  <!-- header -->
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-grid>
          <ion-row class="align-center" id="row">
            <ion-col size="9" class="d-flex">
              <ion-buttons class="mr-2" @click="$emit('close-modal')">
                <ion-icon size="small" :icon="arrowBackOutline"></ion-icon>
              </ion-buttons>
              <ion-label class="fs-4 fw-bold">{{ $t('payment') }}</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-grid>
        <div class="pa-3">
          <!-- Fluid section -->
          <div class="card-item" v-if="isShowFluidPayment" @click="chooseCard({ card_type: 'fluid' })">
            <ion-row class="w-100">
              <ion-col size="2" class="d-flex align-center pl-3">
                <ion-img :src="FLUID_ICON" />
              </ion-col>
              <ion-col size="10" class="d-flex align-center">
                <ion-label>
                  {{ $t('fluid') }}
                </ion-label>
              </ion-col>
            </ion-row>
          </div>
          <div v-if="isShowFluidPayment" class="fluid-warning">
            <ion-row>
              <ion-col size="2"
                ><ion-icon color="warning" class="fs-6" :icon="alertCircleOutline"></ion-icon
              ></ion-col>
              <ion-col size="10">{{ $t('orderB2b.fluidWarning') }}</ion-col>
            </ion-row>
          </div>

          <!-- Credit term section -->
          <div
            class="card-item mt-3"
            @click="chooseCard()"
            :class="{ 'disable-selection': isShowFluidPayment, '': !isShowFluidPayment }"
            v-if="user && !user.is_b2c"
          >
            <ion-row class="w-100">
              <ion-col size="2" class="d-flex align-center pl-3">
                <ion-icon :icon="cashOutline" class="fs-4"></ion-icon>
              </ion-col>
              <ion-col size="10" class="d-flex align-center">
                <ion-label>
                  {{ $t('credit_term') }}
                  ({{ `${customerView?.customer_credit_term.displayName}` }})
                </ion-label>
              </ion-col>
            </ion-row>
          </div>

          <!-- Paynow section -->
          <div
            class="card-item"
            v-if="isShowPaynow"
            @click="chooseCard({ card_type: 'paynow' })"
            :class="{ 'disable-selection': isShowFluidPayment, '': !isShowFluidPayment }"
          >
            <ion-row class="w-100">
              <ion-col size="2" class="d-flex align-center pl-3">
                <ion-img :src="getCardImage('paynow')" />
              </ion-col>
              <ion-col size="10" class="d-flex align-center">
                <ion-label>
                  {{ $t('paynow') }}
                </ion-label>
              </ion-col>
            </ion-row>
          </div>

          <!-- Credit card section -->
          <div
            v-if="isShowCreditCards && mapStripeCard.length > 0"
            :class="{ 'disable-selection': isShowFluidPayment }"
          >
            <ion-row
              class="w-100 card-item"
              v-for="(item, index) in mapStripeCard"
              :key="index"
              @click="chooseCard({ ...item, card_type: 'stripe' })"
            >
              <ion-col size="2" class="d-flex align-center pl-3">
                <ion-img :src="getCardImage(item.brand)" />
              </ion-col>
              <ion-col size="7" class="d-flex align-center">
                <div class="d-flex flex-column">
                  <div>
                    <ion-label> {{ item.brand }} ({{ item.funding }}) </ion-label>
                  </div>
                  <div class="">
                    <ion-label color="grey5" class="fs-1"
                      >{{ item.last4 }} {{ item.exp_month }}/{{ item.exp_year }}</ion-label
                    >
                  </div>
                </div>
              </ion-col>
              <ion-col size="3" class="d-flex align-center">
                <div class="card-badge" v-if="item.isDefault">
                  <ion-label class="fw-500 text-white">{{ $t('default') }}</ion-label>
                </div>
              </ion-col>
            </ion-row>
          </div>

          <!-- Nets card section -->
          <div
            v-if="isShowNetsCard && listNetsCard.length > 0"
            :class="{ 'disable-selection': isShowFluidPayment }"
          >
            <div v-for="(listCard, index) in listNetsCard" :key="index">
              <ion-row
                class="w-100 card-item"
                @click="chooseCard({ ...listNetsCard[index], card_type: 'nets' })"
              >
                <ion-col size="2" class="d-flex align-center pl-3">
                  <ion-img :src="NetsCardBrand" />
                </ion-col>
                <ion-col size="10" class="d-flex align-center">
                  <div class="d-flex flex-column">
                    <div>
                      <ion-label
                        >{{ listCard.issuer_short_name }} {{ $t('accountPage.nets_card_type') }}</ion-label
                      >
                    </div>
                    <div class="">
                      <ion-label color="grey5" class="fs-1"
                        >{{ listCard.last_4_digits_fpan }} {{ listCard.mtoken_expiry_date.substr(2) }}/{{
                          listCard.mtoken_expiry_date.substr(0, 2)
                        }}</ion-label
                      >
                    </div>
                  </div>
                </ion-col>
              </ion-row>
            </div>
          </div>
        </div>
      </ion-grid>
    </ion-content>

    <ion-footer v-if="user && user.is_b2c">
      <ion-toolbar>
        <div class="pa-1">
          <ion-button expand="block" @click="goToAccountPage">{{ $t('manage_cards') }}</ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import NetsCardBrand from '@/assets/images/nets-brand.png';
import { DEFAULT_CARD, FLUID_ICON, TENANT_IDS } from '@/modules/b2b/constants';
import { stripe } from '@/modules/b2b/services/libs/payment';
import { ACTIONS as ACTIONS_PAYMENT } from '@/modules/b2b/store/payment/actions';
import { Device } from '@capacitor/device';
import { alertCircleOutline, arrowBackOutline, cashOutline, closeOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';

const { mapGetters: mapGettersPayment, mapActions: mapActionsPayment } = createNamespacedHelpers(
  'b2b/payment'
);

export default defineComponent({
  name: 'credit-card',

  emits: ['close-modal', 'choose-card', 'go-to-account-page'],

  props: {
    customerView: {
      type: Object,
      required: true
    },
    isShowNetsCard: {
      type: Boolean,
      required: true
    },
    isShowFluidPayment: {
      type: Boolean,
      required: false
    }
  },
  inject: ['$storage'],
  data() {
    return {
      selectCompany: [],
      deviceId: '',
      user: null
    };
  },
  setup() {
    const isShowPaynow = ref(false);
    const setShowPaynow = (state) => (isShowPaynow.value = state);
    return {
      arrowBackOutline,
      closeOutline,
      NetsCardBrand,
      cashOutline,
      isShowPaynow,
      setShowPaynow,
      FLUID_ICON,
      alertCircleOutline
    };
  },

  async mounted() {
    this.selectCompany = await this.$storage.getSelectedCompany();
    this.user = await this.$storage.getUser();

    // check country to show paynow
    // temp disable paynow
    // if (this.user?.country?.name === 'SG' && this.user?.is_b2c) {
    //   this.setShowPaynow(true);
    // }

    // get device id
    this.deviceId = (await Device.getId()).uuid;
    if (this.isShowCreditCards) {
      await this[ACTIONS_PAYMENT.GET_LIST_NETS_CARD](this.deviceId);
      await this[ACTIONS_PAYMENT.GET_LIST_CUSTOMER_CARD](this.selectCompany.id);
      await this[ACTIONS_PAYMENT.GET_DEFAULT_CARD](this.selectCompany.id);
    }
  },
  computed: {
    ...mapGettersPayment(['cardList', 'listNetsCard', 'defaultCardId']),
    isShowCreditCards() {
      if (!this.customerView || !this.user) return false;
      return TENANT_IDS.includes(this.customerView.tenant_id) && this.user.is_b2c;
    },
    mapStripeCard() {
      if (this.cardList && this.cardList) {
        const cardData = this.cardList.map((item) => ({
          ...item,
          isDefault: this.defaultCardId ? item.id === this.defaultCardId : false
        }));
        cardData.sort((a, b) => (a.isDefault === b.isDefault ? 0 : a.isDefault ? -1 : 1));
        return cardData;
      } else {
        return [];
      }
    }
  },
  methods: {
    ...mapActionsPayment([
      ACTIONS_PAYMENT.GET_LIST_CUSTOMER_CARD,
      ACTIONS_PAYMENT.GET_LIST_NETS_CARD,
      ACTIONS_PAYMENT.GET_DEFAULT_CARD
    ]),
    getCardImage(brand) {
      return stripe.getCardImage(brand);
    },

    chooseCard(card = DEFAULT_CARD) {
      if (card.card_type !== 'fluid' && this.isShowFluidPayment) return;
      this.$emit('choose-card', card);
    },

    goToAccountPage() {
      this.$emit('close-modal');
      this.$emit('go-to-account-page');
    }
  }
});
</script>

<style lang="scss" scoped>
.card-item {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 2px 2px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
#row {
  height: 50px;
}
.fluid-warning {
  background-color: #fdf5ed;
}
.disable-selection {
  position: relative;
  &::after {
    display: block;
    position: absolute;
    content: '';
    width: 100%;
    height: 50px;
    top: 0;
    background: black;
    opacity: 0.5;
  }
}
.card-badge {
  color: white;
  background: #04565a;
  padding: 3px 5px;
  font-size: 12px;
  border-radius: 5px;
}
</style>
